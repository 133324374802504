var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}]},[(_vm.usaDictado || (_vm.variables && _vm.variables.length))?_c('v-row',{staticClass:"px-4",attrs:{"justify":"end"}},[(_vm.usaDictado)?_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"4","align":"end","align-self":"center"}},[_c('v-btn',{staticClass:"btn-dictar mb-1",attrs:{"dense":"","outlined":"","color":"primary"},on:{"click":_vm.onDictado}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.microIcon))]),_vm._v(" Dictar diagnóstico")],1)],1):_vm._e()],1):_vm._e(),_c('div',{class:_vm.fullScreen ? 'fullScreen' : ''},[(_vm.editor)?_c('div',{staticClass:"toolbar pt-1"},[_c('v-row',{staticClass:"px-4 py-1 max-h",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-0 ",attrs:{"cols":"9","align-self":"start"}},[_c('v-row',{staticClass:"px-4 max-h"},[_c('v-select',{staticClass:"heading-combo px-0 mr-1",attrs:{"dense":"","solo":"","label":"Párrafo","items":_vm.headingItems,"item-text":"value","item-value":"id","return-object":""},on:{"change":_vm.onChangeHeading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.style)},[_vm._v(_vm._s(item.value))])]}}],null,false,3862055131),model:{value:(_vm.headingSelected),callback:function ($$v) {_vm.headingSelected=$$v},expression:"headingSelected"}}),_c('v-select',{staticClass:"heading-font-size px-0 mx-1",attrs:{"dense":"","solo":"","label":"Tamaño de fuente","items":_vm.fontSizes},on:{"change":_vm.onChangeFontSize},model:{value:(_vm.fontSizeSelected),callback:function ($$v) {_vm.fontSizeSelected=$$v},expression:"fontSizeSelected"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.boldIcon))])],1)]}}],null,false,3877838151)},[_c('span',[_vm._v("Negrita")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.italicIcon))])],1)]}}],null,false,2982491512)},[_c('span',[_vm._v("Cursiva")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('underline') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .toggleUnderline()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.underlineIcon))])],1)]}}],null,false,377444132)},[_c('span',[_vm._v("Subrayado")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.strikeIcon))])],1)]}}],null,false,4086854288)},[_c('span',[_vm._v("Tachado")])]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('textStyle') },attrs:{"icon":"","small":""},on:{"click":_vm.onClickColorText,"mouseover":function($event){(_vm.showColorText = true), (_vm.showColorFill = false)},"mouseleave":_vm.closeMenu}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.colorTextIcon))])],1)]}}],null,false,4050429851),model:{value:(_vm.showColorText),callback:function ($$v) {_vm.showColorText=$$v},expression:"showColorText"}},[(_vm.showColorText)?_c('ColorPicker',{attrs:{"title":'Color de texto'},on:{"mouseleave":function($event){_vm.showColorText = false},"onSelect":function($event){return _vm.onColorSelect($event, 'colorText')},"onClear":function($event){_vm.editor
                      .chain()
                      .focus()
                      .unsetColor()
                      .run(),
                      (_vm.showColorText = false)}}}):_vm._e()],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('highlight') },attrs:{"icon":"","small":""},on:{"click":_vm.onClickColorFill,"mouseover":function($event){(_vm.showColorFill = true), (_vm.showColorText = false)},"mouseleave":_vm.closeMenu}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.colorFillIcon))])],1)]}}],null,false,1751526721),model:{value:(_vm.showColorFill),callback:function ($$v) {_vm.showColorFill=$$v},expression:"showColorFill"}},[(_vm.showColorFill)?_c('ColorPicker',{attrs:{"title":'Color de relleno'},on:{"onSelect":function($event){return _vm.onColorSelect($event, 'colorFill')},"onClear":function($event){_vm.editor
                      .chain()
                      .focus()
                      .unsetHighlight()
                      .run(),
                      (_vm.showColorFill = false)}}}):_vm._e()],1),_c('v-divider',{staticClass:"mt-0 mx-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive({ textAlign: 'justify' }) || _vm.editor.isActive({ textAlign: 'left' }) || _vm.editor.isActive({ textAlign: 'right' }) || _vm.editor.isActive({ textAlign: 'center' })},attrs:{"icon":"","small":"","rounded":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(_vm._s(_vm.alignLeftIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Alinear")])])]}}],null,false,3811337435),model:{value:(_vm.menuAlinear),callback:function ($$v) {_vm.menuAlinear=$$v},expression:"menuAlinear"}},[_c('v-card',{staticClass:"menu-card-alinear"},[_c('v-row',{staticClass:"menu-row pb-2 pt-0",attrs:{"dense":"","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive({ textAlign: 'left' }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .setTextAlign('left')
                              .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.alignLeftIcon))])],1)]}}],null,false,2599216019)},[_c('span',[_vm._v("Alinear izquierda")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{
                            'is-active': _vm.editor.isActive({ textAlign: 'center' }),
                          },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .setTextAlign('center')
                              .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.alignCenterIcon))])],1)]}}],null,false,703364207)},[_c('span',[_vm._v("Centrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{
                            'is-active': _vm.editor.isActive({ textAlign: 'right' }),
                          },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .setTextAlign('right')
                              .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.alignRightIcon))])],1)]}}],null,false,1796172868)},[_c('span',[_vm._v("Alinear derecha")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{
                            'is-active': _vm.editor.isActive({ textAlign: 'justify' }),
                          },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .setTextAlign('justify')
                              .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.alignJustifyIcon))])],1)]}}],null,false,3295487754)},[_c('span',[_vm._v("Justificar")])])],1)],1)],1),_c('v-divider',{staticClass:"mt-0 mx-1 my-4",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .indent()
                        .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.indentIcon))])],1)]}}],null,false,1722128860)},[_c('span',[_vm._v("Tabular a la derecha")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .outdent()
                        .run()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.outdentIcon))])],1)]}}],null,false,1426058140)},[_c('span',[_vm._v("Tabular a la izquierda")])]),_c('v-divider',{staticClass:"mt-0 mx-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('bulletList') || _vm.editor.isActive('orderedList') || _vm.editor.isActive('taskList') },attrs:{"icon":"","small":"","rounded":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(_vm._s(_vm.ulIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Listas")])])]}}],null,false,1179493880),model:{value:(_vm.menuListas),callback:function ($$v) {_vm.menuListas=$$v},expression:"menuListas"}},[_c('v-card',{staticClass:"menu-card-listas"},[_c('v-row',{staticClass:"menu-row pb-2 pt-0",attrs:{"dense":"","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .toggleBulletList()
                              .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.ulIcon))])],1)]}}],null,false,3030223771)},[_c('span',[_vm._v("Lista")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .toggleOrderedList()
                              .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.olIcon))])],1)]}}],null,false,3943967841)},[_c('span',[_vm._v("Lista ordenada")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('taskList') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                              .chain()
                              .focus()
                              .toggleTaskList()
                              .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.listCheckboxIcon))])],1)]}}],null,false,2057113299)},[_c('span',[_vm._v("Lista de tareas")])])],1)],1)],1),_c('v-divider',{staticClass:"mt-0 mx-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('link') },attrs:{"icon":"","small":""},on:{"click":_vm.setLink}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.linkIcon))])],1)]}}],null,false,4139564534)},[_c('span',[_vm._v("Enlaces")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.editor.isActive('blockquote') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .toggleBlockquote()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.quoteIcon))])],1)]}}],null,false,3012959160)},[_c('span',[_vm._v("Cita")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .setHorizontalRule()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.minusIcon))])],1)]}}],null,false,2830423049)},[_c('span',[_vm._v("Linea horizontal")])]),_c('v-divider',{staticClass:"mt-0 ml-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-1",attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{on:{"click":_vm.onAddTableClick}},[_vm._v(_vm._s(_vm.addTableIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Tablas")])])]}}],null,false,2763500650),model:{value:(_vm.menuTablasGlobal),callback:function ($$v) {_vm.menuTablasGlobal=$$v},expression:"menuTablasGlobal"}},[_c('div',{staticClass:"contenedor-lista pa-2"},[_c('v-menu',{attrs:{"offset-x":"","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"}},[_c('span',[_vm._v("Insertar tabla")]),_c('v-icon',[_vm._v(_vm._s(_vm.subMenuIcon))])],1)])]}}],null,false,2408080045),model:{value:(_vm.menuTablasInsert),callback:function ($$v) {_vm.menuTablasInsert=$$v},expression:"menuTablasInsert"}},[_c('div',{staticClass:"contenedor-lista pa-2"},[_c('v-row',{staticClass:"py-0 px-4"},[_c('v-col',{staticClass:"pa-0 d-flex align-start",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"ma-0",attrs:{"id":"chk-with-header"},on:{"click":_vm.onChangeWithHeaderTable},model:{value:(_vm.withHeaderRow),callback:function ($$v) {_vm.withHeaderRow=$$v},expression:"withHeaderRow"}}),_c('label',{staticClass:"my-label pt-1",attrs:{"for":"chk-with-header"}},[_vm._v("Incluir cabecera")])],1)],1),_c('div',{staticClass:"cuadricula"},_vm._l((7),function(fila,rowIndex){return _c('div',{key:rowIndex,staticClass:"max-w"},_vm._l((7),function(cuadro,colIndex){return _c('div',{key:colIndex,staticClass:"my-1",class:{
                              cuadrado: true,
                              hovered: _vm.isHovered(rowIndex, colIndex),
                            },on:{"mouseover":function($event){return _vm.resaltarFilaColumna(rowIndex, colIndex)},"click":function($event){_vm.onClickInsertarTabla(), (_vm.menuTablasGlobal = false)}}})}),0)}),0),_c('v-row',{staticClass:"px-4",attrs:{"justify":"end"}},[_vm._v(" "+_vm._s(_vm.qRow)+" x "+_vm._s(_vm.qCol)+" ")])],1)]),_c('v-divider',{staticClass:"my-1"}),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .addColumnBefore()
                        .run()}}},[_c('span',[_vm._v("Agregar columna antes")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .addColumnAfter()
                        .run()}}},[_c('span',[_vm._v("Agregar columna después")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor.chain().focus().toggleHeaderColumn().run()}}},[_c('span',[_vm._v("Convertir columna en cabecera")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor.chain().focus().deleteColumn().run()}}},[_c('span',[_vm._v("Eliminar columna")])]),_c('v-divider',{staticClass:"my-1"}),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .addRowBefore()
                        .run()}}},[_c('span',[_vm._v("Agregar fila antes")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .addRowAfter()
                        .run()}}},[_c('span',[_vm._v("Agregar fila después")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor.chain().focus().toggleHeaderRow().run()}}},[_c('span',[_vm._v("Convertir fila en cabecera")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor.chain().focus().deleteRow().run()}}},[_c('span',[_vm._v("Eliminar fila")])]),_c('v-divider',{staticClass:"my-1"}),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .mergeOrSplit()
                        .run()}}},[_c('span',[_vm._v("Combinar/separar celdas")])]),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor.chain().focus().toggleHeaderCell().run()}}},[_c('span',[_vm._v("Convertir celda en cabecera")])]),_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{ 'li-disabled': !_vm.hasTable }},'div',attrs,false),on),[_c('span',[_vm._v("Color de celda")])])]}}],null,false,691930818),model:{value:(_vm.menuColorCelda),callback:function ($$v) {_vm.menuColorCelda=$$v},expression:"menuColorCelda"}}),_c('div',{on:{"click":_vm.onChangecolorTable}},[_c('ColorPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuColorCelda),expression:"menuColorCelda"}],attrs:{"title":'Color de fondo'},on:{"onSelect":function($event){return _vm.onColorSelect($event,'cellBgColor')}}})],1),_c('v-menu',{attrs:{"offset-x":"","close-on-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({class:{ 'li-disabled': !_vm.hasTable }},on),[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"}},[_c('span',[_vm._v("Bordes de celda")]),_c('v-icon',[_vm._v(_vm._s(_vm.subMenuIcon))])],1)])]}}],null,false,2069608485),model:{value:(_vm.menuBordesCelda),callback:function ($$v) {_vm.menuBordesCelda=$$v},expression:"menuBordesCelda"}},[_c('div',{staticClass:"contenedor-lista pa-2"},_vm._l((_vm.borderStyles),function(borde,idx){return _c('div',{key:idx,on:{"click":function($event){return _vm.onBorderChange(borde.value)}}},[_vm._v(" "+_vm._s(borde.name)+" ")])}),0)]),_c('v-divider',{staticClass:"my-1"}),_c('div',{class:{ 'li-disabled': !_vm.hasTable },on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .deleteTable()
                        .run()}}},[_c('span',[_vm._v("Eliminar tabla")])])],1)]),(!_vm.hideImage)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-1",attrs:{"icon":""},on:{"click":_vm.openModalImage}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.imageIcon))])],1)]}}],null,false,3897282886)},[_c('span',[_vm._v("Agregar imágen")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":"","disabled":!_vm.canUndo},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .undo()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.undoIcon))])],1)]}}],null,false,4045828332)},[_c('span',[_vm._v("Atrás")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":"","disabled":!_vm.editor
                        .can()
                        .chain()
                        .focus()
                        .redo()
                        .run()},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .redo()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.redoIcon))])],1)]}}],null,false,1464774252)},[_c('span',[_vm._v("Adelante")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor
                        .chain()
                        .focus()
                        .unsetAllMarks()
                        .run()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.clearFormatIcon))])],1)]}}],null,false,248915251)},[_c('span',[_vm._v("Limpiar formatos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",attrs:{"icon":"","small":""},on:{"click":_vm.clearEditor}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.eraserIcon))])],1)]}}],null,false,2563591577)},[_c('span',[_vm._v("Limpiar texto")])]),(!_vm.fullScreen)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.displayPreview },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.displayPreview = !_vm.displayPreview}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.previewIcon))])],1)]}}],null,false,2523203463)},[_c('span',[_vm._v("Previsualizar")])]):_vm._e(),(!_vm.esEncabezadoPie)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0 mt-2",class:{ 'is-active': _vm.fullScreen },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.fullScreen = !_vm.fullScreen}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.fullScreenIcon))])],1)]}}],null,false,3305155453)},[_c('span',[_vm._v("Pantalla completa")])]):_vm._e()],1)],1),_c('v-col',{staticClass:"pa-0 max-h pl-8",attrs:{"cols":"3","align":"end","align-self":"end"}},[(_vm.variables && _vm.variables.length)?_c('v-select',{ref:"comboVariables",staticClass:"combo-variables",attrs:{"solo":"","dense":"","items":_vm.variables,"clearable":"","item-text":"nombre","item-value":"variable","return-object":_vm.usaAdicionales ? true : false,"label":_vm.labelVariables ? _vm.labelVariables : 'Insertar variable'},on:{"input":_vm.insertarVariable},model:{value:(_vm.variableSelected),callback:function ($$v) {_vm.variableSelected=$$v},expression:"variableSelected"}}):_vm._e()],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-editor-wraper",on:{"click":_vm.onClickEditor}},[_c('editor-content',{class:{ 'h-editor-wraper-fullScreen': _vm.fullScreen, 'small-h': _vm.esEncabezadoPie },attrs:{"editor":_vm.editor,"id":("text-editor-" + _vm.uniqueId)},nativeOn:{"keydown":function($event){return _vm.onKeydown($event)}}})],1)]),(_vm.displayPreview)?_c('div',{staticClass:"mt-4 pa-3 preview-container",domProps:{"innerHTML":_vm._s(_vm.getContent)}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showInputImage),callback:function ($$v) {_vm.showInputImage=$$v},expression:"showInputImage"}},[(_vm.showInputImage)?_c('AddImageModal',{on:{"cancelar":function($event){_vm.showInputImage = false},"on-updated-file":_vm.onUpdatedFile,"insert-image-from-url":_vm.insertImageFromUrl}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }