<template>
  <div>
    <v-card>
      <v-card-title class="pb-1">
        Cargar imagen
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="upload-form"
        id="upload-form"
        @submit.prevent="insertarImagen"
      >
        <v-card-text class="py-0">
          <!-- <v-switch
            v-model="chkImageExterna"
            @change="chkImageLocal = !chkImageLocal"
            class="no-margin"
          ></v-switch> -->
          <v-text-field
            dense
            outlined
            :disabled="chkImageLocal"
            :readonly="chkImageLocal"
            v-model="urlImagenExterna"
            label="Agregar imagen desde url"
            hint="Ej: https://www.ejemplo.com/imagen"
          ></v-text-field>

          <!-- <v-divider class="mt-0 pb-2"></v-divider> -->

          <!-- <v-row class="px-4">
            <v-col cols="2" class="pa-0">
              <v-switch
                v-model="chkImageLocal"
                @change="chkImageExterna = !chkImageExterna"
                class="no-margin"
              ></v-switch>
            </v-col>
            <v-col
              cols="10"
              class="pa-0 pt-1"
              align-self="start"
              align="center"
            >
              <p class="mb-0">Agregar imagen desde el ordenador</p>
            </v-col> -->
          <!-- </v-row> -->
          <!-- <div :class="{ disable: chkImageExterna }">
            <v-file-input
              accept="image/*"
              v-model="fileImg"
              outlined
              dense
              label="Seleccionar archivo"
              prepend-icon="mdi-camera"
              @change="fileChange"
              id="up"
              ref="file"
              show-size
              hide-details
              class="my-2 pb-2"
            ></v-file-input> -->
            <!-- <br> -->
            <!-- <v-row justify="start" class="px-0  mx-0 my-4 input-resize">
              <v-col cols="6" class="pt-1 pb-0">
                <div class="size-label">Alto:</div>
                <v-text-field
                  dense
                  outlined
                  :disabled="!file"
                  :readonly="!file"
                  v-model="imgUploadedAlto"
                  v-mask="'####'"
                  :rules="
                    chkImageLocal
                      ? rules.required.concat(rules.positiveNumberHigher0)
                      : []
                  "
                >
                  <template v-slot:append>
                    <p class="mb-0 pt-1">px</p>
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="6" class="pt-1 pb-0">
                <div class="size-label">Ancho:</div>
                <v-text-field
                  dense
                  outlined
                  :disabled="!file"
                  :readonly="!file"
                  v-model="imgUploadedAncho"
                  v-mask="'####'"
                  :rules="
                    chkImageLocal
                      ? rules.required.concat(rules.positiveNumberHigher0)
                      : []
                  "
                >
                  <template v-slot:append>
                    <p class="mb-0 pt-1">px</p>
                  </template></v-text-field
                >
              </v-col>
            </v-row>
          </div> -->
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-row justify="end" class="to-right mx-2">
            <v-btn @click="$emit('cancelar')" dense outlined class="">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dense
              class="ml-4"
              :loading="isLoading"
              type="submit"
              form="upload-form"
              :disabled="chkImageLocal ? !isFormValid : !urlImagenExterna"
            >
              Subir
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import rules from "@/utils/helpers/rules";
import axios from "axios";
import { mask } from "vue-the-mask";

export default {
  props: {},
  directives: { mask },

  data() {
    return {
      isFormValid: false,
      rules: rules,
      isLoading: false,
      imgUploadedAlto: "",
      imgUploadedAncho: "",
      imgUploadedAltoOriginal: "",
      imgUploadedAnchoOriginal: "",
      file: null,
      fileImg: null,
      myImage: null,
      urlImagenExterna: "",
      chkImageExterna: true,
      chkImageLocal: false
    };
  },
  methods: {
    fileChange() {
      this.isLoading = true;
      this.file = this.fileImg;
      if (!this.file) {
        this.isLoading = false;
        return;
      }
      // leer la imagen para mostrar el ancho y alto original
      const reader = new FileReader();
      reader.onload = e => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          this.imgUploadedAnchoOriginal = image.width;
          this.imgUploadedAltoOriginal = image.height;
          this.imgUploadedAncho = image.width;
          this.imgUploadedAlto = image.height;
          this.isLoading = false;
          this.myImage = image;
        };
      };
      reader.readAsDataURL(this.file);
    },

    async insertarImagen() {
      this.isLoading = true;
      // Imagen desde URL
      if (this.chkImageExterna) {
        if (this.urlImagenExterna?.length) {
          this.$emit("insert-image-from-url", this.urlImagenExterna);
        }
      } else {
        // Imagen local
        let resizedImage;
        if (
          this.imgUploadedAnchoOriginal != this.imgUploadedAncho ||
          this.imgUploadedAltoOriginal != this.imgUploadedAlto
        ) {
          resizedImage = await this.resizeImage(
            this.myImage,
            this.imgUploadedAncho,
            this.imgUploadedAlto
          );
        }

        var formData = new FormData();
        if (resizedImage) {
          formData.append("File", resizedImage.file);
        } else {
          formData.append("File", this.file);
        }
          formData.append("ArchivoModulo", 4);
        let token = localStorage.getItem("token");
        try {
          axios({
            url: "api/archivo/save-img",
            method: "POST",
            data: formData,
            headers: { Authorization: `Bearer ${token}` }
          })
            .then(async result => {
              let archivoSubidoId = result.data;
              // Emitir al padre
              this.$emit("on-updated-file", archivoSubidoId);
              this.showInputImage = false;
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } catch (error) {
          console.log("error:", error);
          this.isLoading = false;
          this.showInputImage = false;
        }
      }
    },

    resizeImage(image, newWidth, newHeight) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      return new Promise(resolve => {
        canvas.toBlob(blob => {
          resolve({
            file: new File([blob], "resizedImage.jpg", { type: "image/jpeg" }),
            width: newWidth,
            height: newHeight
          });
        }, "image/jpeg");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.no-margin ::v-deep .v-input__slot,
.no-margin {
  margin: 0 !important;
}
.disable {
  opacity: 0.4;
  pointer-events: none;
}
</style>
