<template>
	<node-view-wrapper as="span" class="image-container">
		<img v-bind="node.attrs"
			v-click-outside="onClickOutside"
      :draggable="isDraggable"
      :data-drag-handle="isDraggable" ref="resizableImg"
      @click="toggleResize"
      :class="{'image-resize': isSelected }" />
      <!-- icon de resize -->
			<div v-if="isSelected">
				<div v-for="btn in botonesResize" :key="btn.name"
					:class="`icon-container-${btn.position}`"
					@mousedown="startResize($event, btn.position)"
					@mousemove="resizeImage"
					@mouseup="stopResize($event, btn.position)">
					<span class="resize-icon"></span>
				</div>
      </div>
	</node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

export default {
	components: {
		NodeViewWrapper
	},

	props: nodeViewProps,

	data() {
		return {
			//isResizing: false,
			lastMovement: 0,
			aspectRatio: 0,
      isSelected: false,

      isResizing: false,
      initialMouseX: 0,
      initialMouseY: 0,
      initialWidth: 0,
      initialHeight: 0,
			botonesResize: [
				{
					name: 'top-left',
					position: 'tl'
				},
				{
					name: 'top-right',
					position: 'tr'
				},
				{
					name: 'bottom-left',
					position: 'bl'
				},
				{
					name: 'bottom-right',
					position: 'br'
				},
			],
			currentRezisePosition: '',
		};
	},

	computed: {
    isDraggable() {
			return this.node?.attrs?.isDraggable;
		}
	},

	mounted() {
		this.$refs.resizableImg.onload = () => {
			this.aspectRatio = this.$refs.resizableImg.naturalWidth / this.$refs.resizableImg.naturalHeight;
		};
	},

	methods: {
		onClickOutside() {
			this.isSelected = false;
		},
    startResize(event, position) {
			this.currentRezisePosition = position;
      this.isResizing = true;
      this.initialMouseX = event.clientX;
      this.initialMouseY = event.clientY;
      this.initialWidth = this.$refs.resizableImg.width;
      this.initialHeight = this.$refs.resizableImg.height;

      document.addEventListener("mousemove", this.resizeImage);
      document.addEventListener("mouseup", this.stopResize);
    },
    stopResize() {
      this.isResizing = false;
			this.currentRezisePosition = '';
      document.removeEventListener("mousemove", this.resizeImage);
      document.removeEventListener("mouseup", this.stopResize);
    },
    resizeImage(event) {
      if (this.isResizing) {
        const deltaX = event.clientX - this.initialMouseX;
        const newWidth = (this.currentRezisePosition === 'tl' || this.currentRezisePosition === 'bl') ? this.initialWidth - deltaX : this.initialWidth + deltaX;
        const newHeight = (this.initialHeight / this.initialWidth) * newWidth;
        // Aplicar el nuevo tamaño a la imagen
        this.updateAttributes({ width: newWidth, height: newHeight });
        this.editor.chain().focus().toggleResizable().run();
      }
    },


    toggleResize() {
      this.isSelected = !this.isSelected;
		},
		resizeAspectRatio(grow) {
			let calcW;
			let calcH;

			if (grow) {
				calcH = (this.$refs.resizableImg.height + 5 );
			} else {
				calcH = (this.$refs.resizableImg.height - 5);
			}

			calcW = calcH * this.aspectRatio;

			this.updateAttributes({ width: calcW, height: calcH });
		}
	}
};
</script>
<style scoped>

.image-container {
  position: relative;
  display: inline-block;
}

.image-resize {
  /* max-width: 100%;
  height: auto;
  display: block; */
  border: 4px solid #02b8ac;
}

.icon-container-tl {
  position: absolute;
  top: 0;
  left: 0;
	cursor: se-resize;
}
.icon-container-tr {
  position: absolute;
  top: 0;
  right: 0;
	cursor: sw-resize;
}
.icon-container-bl {
  position: absolute;
  bottom: 0;
  left: 0;
	cursor: nesw-resize;
}
.icon-container-br {
  position: absolute;
  bottom: 0;
  right: 0;
	cursor: nwse-resize;
}

.resize-icon {
  background: #443ef5;
  padding: 2px;
  margin: -3px -3px;
  width: 15px;  
  height: 15px;
  display: block;
}
</style>