<template>
  <transition name="fade">
    <div v-show="loaded">
      <v-row
        v-if="usaDictado || (variables && variables.length)"
        class="px-4"
        justify="end"
      >
        <v-col
          v-if="usaDictado"
          cols="4"
          class="px-0 py-0"
          align="end"
          align-self="center"
        >
          <v-btn
            @click="onDictado"
            dense
            outlined
            color="primary"
            class="btn-dictar mb-1"
          >
            <v-icon small>{{ microIcon }}</v-icon>
            Dictar diagnóstico</v-btn
          >
        </v-col>
      </v-row>
      <div :class="fullScreen ? 'fullScreen' : ''">

        <!-- TOOLBAR -->
        <div class="toolbar pt-1" v-if="editor">
          <v-row class="px-4 py-1 max-h" justify="space-between">
            <v-col class="pa-0 " cols="9" align-self="start">
              <v-row class="px-4 max-h">
                <!-- HEADING -->
                <v-select
                  class="heading-combo px-0 mr-1"
                  dense
                  solo
                  label="Párrafo"
                  v-model="headingSelected"
                  :items="headingItems"
                  @change="onChangeHeading"
                  item-text="value"
                  item-value="id"
                  return-object
                >
                  <template slot="item" slot-scope="{ item }">
                    <span :style="item.style">{{ item.value }}</span>
                  </template>
                </v-select>

                <!-- FONT SIZE -->
                <v-select
                  class="heading-font-size px-0 mx-1"
                  dense
                  solo
                  label="Tamaño de fuente"
                  v-model="fontSizeSelected"
                  :items="fontSizes"
                  @change="onChangeFontSize"
                >
                </v-select>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .toggleBold()
                          .run()
                      "
                      :class="{ 'is-active': editor.isActive('bold') }"
                      v-on="on"
                    >
                      <v-icon>{{ boldIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Negrita</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .toggleItalic()
                          .run()
                      "
                      :class="{ 'is-active': editor.isActive('italic') }"
                      v-on="on"
                    >
                      <v-icon>{{ italicIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Cursiva</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .toggleUnderline()
                          .run()
                      "
                      :class="{ 'is-active': editor.isActive('underline') }"
                      v-on="on"
                    >
                      <v-icon>{{ underlineIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Subrayado</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .toggleStrike()
                          .run()
                      "
                      :class="{ 'is-active': editor.isActive('strike') }"
                      v-on="on"
                    >
                      <v-icon>{{ strikeIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Tachado</span>
                </v-tooltip>
                <!-- COLOR TEXTO -->
                <v-menu
                  v-model="showColorText"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="onClickColorText"
                      v-bind="attrs"
                      v-on="on"
                      @mouseover="(showColorText = true), (showColorFill = false)"
                      @mouseleave="closeMenu"
                      :class="{ 'is-active': editor.isActive('textStyle') }"
                    >
                      <v-icon>{{ colorTextIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <ColorPicker
                    :title="'Color de texto'"
                    v-if="showColorText"
                    @mouseleave="showColorText = false"
                    @onSelect="onColorSelect($event, 'colorText')"
                    @onClear="
                      editor
                        .chain()
                        .focus()
                        .unsetColor()
                        .run(),
                        (showColorText = false)
                    "
                  />
                </v-menu>
                <!-- COLOR RELLENO -->
                <v-menu
                  v-model="showColorFill"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      class="px-0 mt-2"
                      @click="onClickColorFill"
                      v-bind="attrs"
                      v-on="on"
                      @mouseover="(showColorFill = true), (showColorText = false)"
                      @mouseleave="closeMenu"
                      :class="{ 'is-active': editor.isActive('highlight') }"
                    >
                      <v-icon>{{ colorFillIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <ColorPicker
                    :title="'Color de relleno'"
                    v-if="showColorFill"
                    @onSelect="onColorSelect($event, 'colorFill')"
                    @onClear="
                      editor
                        .chain()
                        .focus()
                        .unsetHighlight()
                        .run(),
                        (showColorFill = false)
                    "
                  />
                </v-menu>

                <v-divider vertical class="mt-0 mx-1"></v-divider>

                <!-- ALINEAR -->
                <v-menu offset-y v-model="menuAlinear">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          icon
                          class="px-0 mt-2"
                          small
                          rounded
                          v-on="{ ...onMenu, ...onTooltip }"
                          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) || editor.isActive({ textAlign: 'left' }) || editor.isActive({ textAlign: 'right' }) || editor.isActive({ textAlign: 'center' })}"
                        >
                          <v-icon>{{ alignLeftIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Alinear</span>
                    </v-tooltip>
                  </template>
                  <v-card class="menu-card-alinear">
                    <v-row dense class="menu-row pb-2 pt-0" justify="center">            
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .setTextAlign('left')
                                .run()
                            "
                            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                            v-on="on"
                          >
                            <v-icon size="20">{{ alignLeftIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Alinear izquierda</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .setTextAlign('center')
                                .run()
                            "
                            :class="{
                              'is-active': editor.isActive({ textAlign: 'center' }),
                            }"
                            v-on="on"
                          >
                            <v-icon size="20">{{ alignCenterIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Centrar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .setTextAlign('right')
                                .run()
                            "
                            :class="{
                              'is-active': editor.isActive({ textAlign: 'right' }),
                            }"
                            v-on="on"
                          >
                            <v-icon size="20">{{ alignRightIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Alinear derecha</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .setTextAlign('justify')
                                .run()
                            "
                            :class="{
                              'is-active': editor.isActive({ textAlign: 'justify' }),
                            }"
                            v-on="on"
                          >
                            <v-icon size="20">{{ alignJustifyIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Justificar</span>
                      </v-tooltip>
                    </v-row>
                  </v-card>
                </v-menu>
                <v-divider vertical class="mt-0 mx-1 my-4"></v-divider>
                <!-- TABULAR -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .indent()
                          .run()
                      "
                      v-on="on"
                    >
                      <v-icon size="20">{{ indentIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tabular a la derecha</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .outdent()
                          .run()
                      "
                      v-on="on"
                    >
                      <v-icon size="20">{{ outdentIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tabular a la izquierda</span>
                </v-tooltip>

                <v-divider vertical class="mt-0 mx-1"></v-divider>

                <!-- LISTAS -->
                <v-menu offset-y v-model="menuListas">
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          icon
                          class="px-0 mt-2"
                          small
                          rounded
                          v-on="{ ...onMenu, ...onTooltip }"
                          :class="{ 'is-active': editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('taskList') }"
                        >
                          <v-icon>{{ ulIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Listas</span>
                    </v-tooltip>
                  </template>
                  <v-card class="menu-card-listas">
                    <v-row dense class="menu-row pb-2 pt-0" justify="center">            
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .toggleBulletList()
                                .run()
                            "
                            :class="{ 'is-active': editor.isActive('bulletList') }"
                            v-on="on"
                          >
                            <v-icon>{{ ulIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Lista</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .toggleOrderedList()
                                .run()
                            "
                            :class="{ 'is-active': editor.isActive('orderedList') }"
                            v-on="on"
                          >
                            <v-icon>{{ olIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Lista ordenada</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="px-0 mt-2"
                            small
                            @click="
                              editor
                                .chain()
                                .focus()
                                .toggleTaskList()
                                .run()
                            "
                            :class="{ 'is-active': editor.isActive('taskList') }"
                            v-on="on"
                          >
                            <v-icon>{{ listCheckboxIcon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Lista de tareas</span>
                      </v-tooltip>
                    </v-row>
                  </v-card>
                </v-menu>




                <v-divider vertical class="mt-0 mx-1"></v-divider>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="setLink"
                      :class="{ 'is-active': editor.isActive('link') }"
                      v-on="on"
                    >
                      <v-icon>{{ linkIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Enlaces</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .toggleBlockquote()
                          .run()
                      "
                      :class="{ 'is-active': editor.isActive('blockquote') }"
                      v-on="on"
                    >
                      <v-icon>{{ quoteIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Cita</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .setHorizontalRule()
                          .run()
                      "
                      v-on="on"
                    >
                      <v-icon>{{ minusIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Linea horizontal</span>
                </v-tooltip>

                <v-divider vertical class="mt-0 ml-1"></v-divider>

                <!-- TABLAS -->
                <v-menu v-model="menuTablasGlobal" offset-y>
                  <template #activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          icon
                          class="px-0 mt-1"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          <v-icon @click="onAddTableClick">{{ addTableIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Tablas</span>
                    </v-tooltip>
                  </template>
                  <div class="contenedor-lista pa-2">
                    <v-menu
                      v-model="menuTablasInsert"
                      offset-x
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                            <span>Insertar tabla</span>
                            <v-icon>{{ subMenuIcon }}</v-icon>
                          </div>
                        </div>
                      </template>
                      <div class="contenedor-lista pa-2">
                        <v-row class="py-0 px-4">
                          <v-col cols="12" class="pa-0 d-flex align-start">
                            <v-checkbox
                              id="chk-with-header"
                              v-model="withHeaderRow"
                              @click="onChangeWithHeaderTable"
                              class="ma-0"></v-checkbox>
                              <label class="my-label pt-1" for="chk-with-header">Incluir cabecera</label>
                          </v-col>
                        </v-row>

                        <div class="cuadricula">
                          <div
                            v-for="(fila, rowIndex) in 7"
                            :key="rowIndex"
                            class="max-w"
                          >
                            <div
                              v-for="(cuadro, colIndex) in 7"
                              :key="colIndex"
                              :class="{
                                cuadrado: true,
                                hovered: isHovered(rowIndex, colIndex),
                              }"
                              class="my-1"
                              @mouseover="resaltarFilaColumna(rowIndex, colIndex)"
                              @click="
                                onClickInsertarTabla(), (menuTablasGlobal = false)
                              "
                            ></div>
                          </div>
                        </div>
                        <v-row justify="end" class="px-4">
                          {{ qRow }} x {{ qCol }}
                        </v-row>
                      </div>
                    </v-menu>

                    <v-divider class="my-1"></v-divider>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .addColumnBefore()
                          .run()
                      "
                    >
                      <span>Agregar columna antes</span>
                    </div>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .addColumnAfter()
                          .run()
                      "
                    >
                      <span>Agregar columna después</span>
                    </div>
                    <div :class="{ 'li-disabled': !hasTable }" @click="editor.chain().focus().toggleHeaderColumn().run()">
                      <span>Convertir columna en cabecera</span>
                    </div>
                    <div :class="{ 'li-disabled': !hasTable }" @click="editor.chain().focus().deleteColumn().run()">
                      <span>Eliminar columna</span>
                    </div>
                    <v-divider class="my-1"></v-divider>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .addRowBefore()
                          .run()
                      "
                    >
                      <span>Agregar fila antes</span>
                    </div>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .addRowAfter()
                          .run()
                      "
                    >
                      <span>Agregar fila después</span>
                    </div>
                    <div :class="{ 'li-disabled': !hasTable }" @click="editor.chain().focus().toggleHeaderRow().run()">
                      <span>Convertir fila en cabecera</span>
                    </div>
                    <div :class="{ 'li-disabled': !hasTable }" @click="editor.chain().focus().deleteRow().run()">
                      <span>Eliminar fila</span>
                    </div>
                    <v-divider class="my-1"></v-divider>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .mergeOrSplit()
                          .run()
                      "
                    >
                      <span>Combinar/separar celdas</span>
                    </div>

                    <div :class="{ 'li-disabled': !hasTable }" @click="editor.chain().focus().toggleHeaderCell().run()">
                      <span>Convertir celda en cabecera</span>
                    </div>
                    
                    <v-menu
                      v-model="menuColorCelda"
                      offset-x
                      >
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" :class="{ 'li-disabled': !hasTable }">
                          <span>Color de celda</span>
                        </div>
                      </template>
                    </v-menu>
                    <div @click="onChangecolorTable">
                      <ColorPicker
                        :title="'Color de fondo'"
                        v-show="menuColorCelda"
                        @onSelect="onColorSelect($event,'cellBgColor')"
                      />
                    </div>

                    <v-menu
                      v-model="menuBordesCelda"
                      offset-x
                      :close-on-click="false"
                      open-on-hover
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on" :class="{ 'li-disabled': !hasTable }">
                          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                            <span>Bordes de celda</span>
                            <v-icon>{{ subMenuIcon }}</v-icon>
                          </div>
                        </div>
                      </template>
                      <div class="contenedor-lista pa-2">
                        <div v-for="(borde, idx) in borderStyles" :key="idx" @click="onBorderChange(borde.value)">
                          {{borde.name}}
                        </div>
                      </div>
                    </v-menu>

                    <v-divider class="my-1"></v-divider>
                    <div
                      :class="{ 'li-disabled': !hasTable }"
                      @click="
                        editor
                          .chain()
                          .focus()
                          .deleteTable()
                          .run()
                      "
                    >
                      <span>Eliminar tabla</span>
                    </div>
                  </div>
                </v-menu>
                
                <!-- IMAGENES -->
                <v-tooltip top v-if="!hideImage">
                  <template #activator="{ on }">
                    <v-btn icon class="px-0 mt-1" @click="openModalImage" v-on="on">
                      <v-icon>{{ imageIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar imágen</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .undo()
                          .run()
                      "
                      :disabled="!canUndo"
                      v-on="on"
                    >
                      <v-icon>{{ undoIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Atrás</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .redo()
                          .run()
                      "
                      :disabled="
                        !editor
                          .can()
                          .chain()
                          .focus()
                          .redo()
                          .run()
                      "
                      v-on="on"
                    >
                      <v-icon>{{ redoIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Adelante</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="
                        editor
                          .chain()
                          .focus()
                          .unsetAllMarks()
                          .run()
                      "
                      v-on="on"
                    >
                      <v-icon>{{ clearFormatIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Limpiar formatos</span>
                </v-tooltip>

                <!-- Limpiar texto -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      @click="clearEditor"
                      v-on="on"
                    >
                      <v-icon>{{ eraserIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Limpiar texto</span>
                </v-tooltip>

                <v-tooltip top v-if="!fullScreen">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      :class="{ 'is-active': displayPreview }"
                      @click="displayPreview = !displayPreview"
                      v-on="on"
                    >
                      <v-icon>{{ previewIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Previsualizar</span>
                </v-tooltip>
                <v-tooltip top v-if="!esEncabezadoPie">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="px-0 mt-2"
                      small
                      :class="{ 'is-active': fullScreen }"
                      @click="fullScreen = !fullScreen"
                      v-on="on"
                    >
                      <v-icon>{{ fullScreenIcon }}</v-icon></v-btn
                    >
                  </template>
                  <span>Pantalla completa</span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col class="pa-0 max-h pl-8" cols="3" align="end" align-self="end">
              <v-select
                v-if="variables && variables.length"
                ref="comboVariables"
                class="combo-variables"
                solo
                dense
                v-model="variableSelected"
                :items="variables"
                clearable
                item-text="nombre"
                item-value="variable"
                :return-object="usaAdicionales ? true : false"
                :label="labelVariables ? labelVariables : 'Insertar variable'"
                @input="insertarVariable"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- <v-divider class="mt-0 mb-2"></v-divider> -->
        <!-- EDITOR -->
        <div class="text-editor-wraper" @click="onClickEditor">
          <editor-content
            :class="{ 'h-editor-wraper-fullScreen': fullScreen, 'small-h': esEncabezadoPie }"
            :editor="editor"
            :id="`text-editor-${uniqueId}`"
            @keydown.native="onKeydown"
          />
        </div>
      </div>
      <!-- <div>{{getContent}}</div> -->

      <!-- PREVIEW -->
      <div v-html="getContent" v-if="displayPreview" class="mt-4 pa-3 preview-container">
      </div>

      <!-- INSERTAR IMAGEN -->
      <v-dialog v-model="showInputImage" max-width="500px">
        <AddImageModal
          v-if="showInputImage"
          @cancelar="showInputImage = false"
          @on-updated-file="onUpdatedFile"
          @insert-image-from-url="insertImageFromUrl"
        />
      </v-dialog>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import axios from "axios";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import ResizableImage from "./resizable-image.js";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import rules from "@/utils/helpers/rules";
import ColorPicker from "./ColorPicker";
import Link from "@tiptap/extension-link";
import AddImageModal from "./AddImageModal.vue";
import { Indent } from  "./indent.js";
import { Text } from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import FontSize from 'tiptap-extension-font-size';

const CustomText = Text.extend({
  addAttributes() {
    return {
        ...this.parent?.()
        }
  }
  // addKeyboardShortcuts() {
  //   return {
  //     'Space': () => {
  //       this.editor.commands.insertContent('\u00A0');
  //       return true;
  //     }
  //   }
  // },
})

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      marginBottom: {
        default: '2px',
        parseHTML: element => element.style.marginBottom || '2px',
        renderHTML: () => {
          return {
            style: `margin: 2px 0px; min-height: 20px`,
          };
        },
      }
    }
  },
})

const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: 'width: 100%',
        renderHTML: () => {
          return {
            style: 'width: 100%; border-collapse: collapse',
          };
        },
      },
      style: {
        renderHTML: () => {
          return {
              style: `margin-bottom: 12px`
            }
        },
      },
    }
  }
});

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      borderStyle: {
        default: '2px solid #ced4da',
        parseHTML: element => element.style.borderStyle || '2px solid #ced4da',
        renderHTML: attributes => {
          return {
            style: `border: ${attributes.borderStyle}`,
          };
        },
      },
      backgroundColor: {
        default: null,
        parseHTML: element => element.hasAttribute("data-background-color") ?
          element.getAttribute("data-background-color") :
          element.style?.backgroundColor ?
            element.style?.backgroundColor
            : null,
        renderHTML: attributes => {
          if (attributes.backgroundColor) {
            return {
              style: `background-color: ${attributes.backgroundColor}`,
            };
          } else if (attributes.style) {
            return {
              style: `background-color: ${attributes.style.backgroundColor}`,
            }
          }
        },
      },
      style: {
        default: 'height: 24px; padding: 1px 3px;'
      }
    }
  },
})

const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        renderHTML: () => {
          return {
              style: `background-color: #eaeaea; border: 2px solid #ced4da; padding: 3px 5px; vertical-align: top;`
            }
        },
      }
    }
  },
})

const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      // extiendo los atributos del padre, luego defino los propios
      ...this.parent?.(),
      style: {
        default: 'height: 24px'
      }
    }
  },
})

export default {
  name: "TextEditor",
  components: {
    EditorContent,
    ColorPicker,
    AddImageModal
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    uniqueIdForThisEdit: {
      type: Number,
      default: Date.now(),
    },
    variables: {
      type: Array,
    },
    usaAdicionales: {
      type: Boolean,
      default: false,
    },
    labelVariables: {
      type: String,
      default: "",
    },
    usaDictado: {
      type: Boolean,
      default: false,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    esEncabezadoPie: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      hoveredRow: -1,
      hoveredCol: -1,
      loaded: false,
      rules: rules,
      microIcon: enums.icons.MICRO,
      pencilIcon: enums.icons.EDIT_CIRCLE,
      boldIcon: enums.icons.FORMAT_BOLD,
      italicIcon: enums.icons.FORMAT_ITALIC,
      underlineIcon: enums.icons.FORMAT_UNDERLINE,
      strikeIcon: enums.icons.FORMAT_STRIKE,
      clearFormatIcon: enums.icons.FORMAT_CLEAR,
      previewIcon: enums.icons.SEE,
      eraserIcon: enums.icons.ERASER,
      undoIcon: enums.icons.UNDO,
      redoIcon: enums.icons.REDO,
      imageIcon: enums.icons.ADD_IMAGE,
      olIcon: enums.icons.OL,
      ulIcon: enums.icons.UL,
      quoteIcon: enums.icons.QUOTE,
      minusIcon: enums.icons.REMOVE,
      listCheckboxIcon: enums.icons.LIST_CHECKBOX,
      alignLeftIcon: enums.icons.ALIGN_LEFT,
      alignRightIcon: enums.icons.ALIGN_RIGHT,
      alignCenterIcon: enums.icons.ALIGN_CENTER,
      alignJustifyIcon: enums.icons.ALIGN_JUSTIFY,
      addTableIcon: enums.icons.TABLE_ADD,
      colorTextIcon: enums.icons.COLOR_TEXT,
      colorFillIcon: enums.icons.COLOR_FILL,
      linkIcon: enums.icons.LINK,
      subMenuIcon: enums.icons.CHEVRON_RIGHT,
      indentIcon: enums.icons.INDENT,
      outdentIcon: enums.icons.OUTDENT,
      fullScreenIcon: enums.icons.FULL_SCREEN,
      fullScreen: false,
      uniqueId: this.uniqueIdForThisEdit,
      urlImg: "",
      editor: null,
      variableSelected: null,
      showInputImage: false,
      isLoading: false,
      imgUploadedAlto: "",
      imgUploadedAncho: "",
      file: null,
      displayPreview: false,
      myImage: null,
      headingSelected: null,
      showColorText: false,
      showColorFill: false,
      showMenuTables: false,
      menuColorCelda: false,
      menuBordesCelda: false,
      menuListas: false,
      menuAlinear: false,
      hasTable: false,
      menuTablasGlobal: false,
      menuTablasInsert: false,
      qRow: 0,
      qCol: 0,
      withHeaderRow: false,
      headingItems: [
        { id: "p", value: "Párrafo", level: 0, style: { "font-size": "14px" } },
        {
          id: "h6",
          value: "Título 6",
          level: 6,
          style: { "font-size": "16px" },
        },
        {
          id: "h5",
          value: "Título 5",
          level: 5,
          style: { "font-size": "18px" },
        },
        {
          id: "h4",
          value: "Título 4",
          level: 4,
          style: { "font-size": "20px" },
        },
        {
          id: "h3",
          value: "Título 3",
          level: 3,
          style: { "font-size": "22px" },
        },
        {
          id: "h2",
          value: "Título 2",
          level: 2,
          style: { "font-size": "24px" },
        },
        {
          id: "h1",
          value: "Título 1",
          level: 1,
          style: { "font-size": "26px" },
        },
      ],
      fontSizes: [ 8,10,12,14,16,18,20,24,26,30,36,46,52,60,72 ],
      fontSizeSelected: 14,
      borderStyles: [
        { name: 'Linea simple', value: '2px groove #ced4da'},
        { name: 'Puntos', value: '2px dotted #ced4da'},
        { name: 'Linea intermitente', value: '2px dashed #ced4da'},
        { name: 'Linea sólida', value: '2px solid #ced4da'},
        { name: 'Sin borde', value: '2px none #ced4da'}
      ],
      asIdListTmp: [],
      previousSelected: null,
      canUndo: false,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        CustomParagraph,
        CustomText,
        StarterKit.configure({
          paragraph: false,
          text: false,
        }),
        Underline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        ResizableImage.configure({
          allowBase64: true,
          inline: true,
        }),
        CustomTable.configure({
          resizable: true,
        }),
        //TableRow,
        CustomTableRow,
        CustomTableHeader,
        CustomTableCell,
        Link.configure({
          openOnClick: true,
        }),
        Indent.configure({
          types: ['listItem', 'paragraph'],
          minLevel: 0,
          maxLevel: 8,
        }),
        FontSize
      ],
      onUpdate: () => {
        this.onUpdateEditor();
        this.$emit("update:value", this.editor.getHTML());
        //this.$emit("update:rawTextContent", this.editor.getText());
      },
    });
    setTimeout(() => (this.loaded = true), 1000);
  },
  computed: {
    getContent() {
      return this.editor?.getHTML();
    },
  },
  watch: {
    value(val) {
      if (!this.editor.getText()) this.canUndo = false;
      if (this.editor.getHTML() === val) return;
      this.editor.commands.setContent(val, false);
    },
    cancel(val) {
      if (val) {
        this.cancelTextEditor();
      }
    }
  },
  methods: {
    ...mapActions({
      deleteImgUploaded: "shared/deleteImgUploaded",
    }),
    onKeydown(e) {
      if (this.fullScreen && e.key == 'Escape') {
        this.fullScreen = false;
      }
    },
    closeMenu(e) {
      if (!e.relatedTarget || !e.relatedTarget.closest(".v-menu__content")) {
        this.showColorText = false;
        this.showColorFill = false;
      }
    },
    onUpdateEditor() {
      this.canUndo = true;
    },
    resaltarFilaColumna(rowIndex, colIndex) {
      this.hoveredRow = rowIndex;
      this.hoveredCol = colIndex;
      this.qRow = colIndex + 1;
      this.qCol = rowIndex + 1;
    },
    isHovered(rowIndex, colIndex) {
      return rowIndex <= this.hoveredRow && colIndex <= this.hoveredCol;
    },
    onAddTableClick() {
      this.hasTable = this.editor.isActive('table');
    },
    onClickInsertarTabla() {
      this.editor
        .chain()
        .focus()
        .insertTable({
          rows: this.qRow,
          cols: this.qCol,
          withHeaderRow: !!this.withHeaderRow,
        })
        .run();
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt(
        "Agregar URL (ej. https://ejemplo.com)",
        previousUrl
      );

      // cancelled
      if (url === null) return;

      // empty
      if (url === "") {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .unsetLink()
          .run();
        return;
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    onClickEditor() {
      const { state } = this.editor;
      const { from } = state.selection;
      const node = state.doc.nodeAt(from);
      if (node) {
        const fontSize = node.marks[0]?.attrs?.fontSize;
        if (fontSize) {
          this.fontSizeSelected = parseInt(fontSize, 10);
        }
        else {
          this.fontSizeSelected = 14;
        }
      } else
        this.fontSizeSelected = 14;

      // setea el combo parrafo segun el valor del texto donde caiga el cursor
      if (this.editor.isActive("paragraph")) {
        this.headingSelected = { id: "p", value: "Párrafo", level: 0 };
        return;
      }
      if (this.editor.isActive("heading", { level: 1 })) {
        this.headingSelected = { id: "h1", value: "H1", level: 1 };
        return;
      }
      if (this.editor.isActive("heading", { level: 2 })) {
        this.headingSelected = { id: "h2", value: "H2", level: 2 };
        return;
      }
      if (this.editor.isActive("heading", { level: 3 })) {
        this.headingSelected = { id: "h3", value: "H3", level: 3 };
        return;
      }
      if (this.editor.isActive("heading", { level: 4 })) {
        this.headingSelected = { id: "h4", value: "H4", level: 4 };
        return;
      }
      if (this.editor.isActive("heading", { level: 5 })) {
        this.headingSelected = { id: "h5", value: "H5", level: 5 };
        return;
      }
      if (this.editor.isActive("heading", { level: 6 })) {
        this.headingSelected = { id: "h6", value: "H6", level: 6 };
        return;
      }
    },
    onClickColorText() {
      if (this.editor.isActive("textStyle")) {
        this.editor
          .chain()
          .focus()
          .unsetColor()
          .run();
        this.showColorText = false;
      }
    },
    onClickColorFill() {
      if (this.editor.isActive("highlight")) {
        this.editor
          .chain()
          .focus()
          .unsetHighlight()
          .run();
        this.showColorText = false;
      }
    },
    onColorSelect(colorSelected, from) {
      if (from === "colorText") {
        this.editor
          .chain()
          .focus()
          .setColor(colorSelected)
          .run();
        this.showColorText = false;
      } 
      else if (from === 'cellBgColor') {
        let attributes = this.editor.getAttributes('tableCell');
        const updatedStyles = this.replaceOrAddBackgroundColor(attributes.style, colorSelected);
        this.editor.chain().focus().setCellAttribute('backgroundColor', colorSelected).run();
        this.editor.chain().focus().setCellAttribute('style', updatedStyles).run();
        setTimeout(() => {
          this.menuColorCelda = false;
          this.menuTablasGlobal = false;
        }, 25);
      }
      else {
        this.editor.chain().focus().toggleHighlight({ color: colorSelected }).run()
        this.showColorFill = false;
      }
    },
    replaceOrAddBackgroundColor(styles, newColor) {
      const regex = /background-color\s*:\s*[^;]+;/gi;
      let updatedStyles = styles.replace(regex, `background-color: ${newColor};`);
      // Si no había ninguna ocurrencia de background-color, agregarlo al final
      if (!regex.test(styles)) {
        updatedStyles += ` background-color: ${newColor};`;
      }
      return updatedStyles;
    },
    onBorderChange(newVal) {
      this.editor.chain().focus().setCellAttribute('borderStyle', newVal).run();
      let attributes = this.editor.getAttributes('tableCell');
      const updatedStyles = this.replaceOrAddBorderStyle(attributes.style, newVal);
      this.editor.chain().focus().setCellAttribute('style', updatedStyles).run();
    },
    replaceOrAddBorderStyle(styles, newVal) {
      const regex = /border\s*:\s*[^;]+;/gi;
      let updatedStyles = styles.replace(regex, `border: ${newVal};`);
      // Si no había ninguna ocurrencia de border, agregarlo al final
      if (!regex.test(styles)) {
        updatedStyles += ` border: ${newVal};`;
      }
      return updatedStyles;
    },
    onChangeHeading() {
      if (!this.headingSelected) return;
      if (this.headingSelected.level == 0) {
        this.editor
          .chain()
          .focus()
          .setParagraph()
          .run();
        return;
      } else {
        this.editor
          .chain()
          .focus()
          .setHeading({ level: this.headingSelected.level })
          .run();
      }
    },
    onChangeFontSize() {
      this.editor.chain().focus().setFontSize(`${this.fontSizeSelected}px`).run();
    },
    async insertarVariable() {
      const variable = this.variableSelected;
      if (!variable) return;
      if (this.usaAdicionales) {
        this.$emit("getAdicionales", variable);
        return;
      }
      this.editor.commands.insertContent(variable);
    },
    insertImageFromUrl(url) {
      this.editor
        .chain()
        .focus()
        .setImage({ src: url })
        .run();
      this.showInputImage = false;
    },
    clearEditor() {
      this.editor.commands.clearContent(true);
    },
    async onUpdatedFile(archivoSubidoId) {
      this.asIdListTmp.push(archivoSubidoId);
      let imgUploaded = await axios.get(
        `api/archivo/get-img?asId=${archivoSubidoId}`
      );
      this.urlImg = `${imgUploaded.config.baseURL}${imgUploaded.config.url}`;
      if (this.urlImg) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: this.urlImg })
          .run();
      } else {
        console.log("no se pudo insertar");
      }
      this.showInputImage = false;
      this.isLoading = false;
    },

    onDictado() {
      this.$emit("onDictado");
    },
    onChangeWithHeaderTable() {
      setTimeout(() => {
        this.menuTablasInsert = true;
      }, 20);
    },
    onChangecolorTable() {
      setTimeout(() => {
        this.menuTablasGlobal = true
        this.menuColorCelda = true
      }, 10);
    },
    openModalImage() {
      this.showInputImage = true;
    },
    cancelTextEditor() {
      if (this.asIdListTmp.length) {
        this.asIdListTmp.forEach(async as => {
          const res = await this.deleteImgUploaded(as);
          if (!res) {
            setTimeout(async () => {
              await this.deleteImgUploaded(as);
            }, 1000);
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.input-resize {
  border: 1px solid #a3a3a3b7;
  border-radius: 4px;
  position: relative;
  max-height: 85px;
}
.btn-edit-size {
  position: absolute;
  z-index: 1;
  right: 0px;
}
.size-label {
  margin-bottom: 2px !important;
  font-size: 14px !important;
}
.theme--light.v-input--is-disabled input {
  color: #0e0d0d;
}
.text-editor-wraper {
  box-shadow: 0px 3px 4px var(--green-color-shadow);
  margin-bottom: 8px;
  //border-radius: 3px;
  // min-height: 300px;
}
.max-h {
  max-height: 50px;
}
.min-h {
  min-height: 45px;
}
.heading-combo {
  max-width: 85px;
  font-size: 12px;
}
.heading-font-size {
  max-width: 47px;
  font-size: 12px;  
}
.btn-dictar {
  font-size: 12px !important;
  min-height: 40px;
}
.toolbar .v-btn {
  font-size: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.toolbar {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 4px;
  background-color: #c7c7c751;
  //border: 1px solid #c7c7c77d;
  border-radius: 3px;
  box-shadow: -1px 2px 2px -1px rgba(106, 132, 134, 0.78);
}

.combo-variables {
  max-width: 240px;
  justify-content: end;
  justify-items: end;
}
.is-active {
  color: #000000fe !important;
  background-color: rgba(201, 201, 201, 0.66) !important;
  font-weight: 900;
  box-shadow: -1px 4px 2px -1px rgba(106, 132, 134, 0.78);
  border: 1px solid #0e0d0d5e !important;
  font-size: 12px !important;
}
/* Basic editor styles */
.tiptap {
  /* scrollbar for mozilla */
  scrollbar-face-color: #E6E6E6; /* Firefox 63 compatibility */
  scrollbar-track-color: #c5c3c3; /* Firefox 63 compatibility */
  scrollbar-color: #c5c3c3 #E6E6E6;
  scrollbar-width: thin !important;

  //min-height: 60vh;
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px 10px;
  p {
    margin-bottom: 2px;
    line-height: 1.2;
  }
  > * + * {
    margin-top: 0.1em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 0.8;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  /*img {
    max-width: 100%;
    height: auto;
  }*/

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
      display: list-item;
    }

    ul[data-type="taskList"] > li {
      display: flex;
    }
  }
}
mark {
  background-image: none;
  padding: 0.125em 0;
  border-radius: 0.25em;
  box-decoration-break: clone;
}
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 10em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.contenedor-lista {
  background-color: #ffffff;

  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.72);
}
.contenedor-lista div {
  cursor: pointer;
}
.li-disabled {
  pointer-events:none !important; 
    opacity:0.6; 
    cursor:none !important; 
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none !important;
}
.cuadricula {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(7, 1fr);
  gap: 0px;
  max-height: 170px;
  max-width: 300px;
  margin: 0 auto;
}

.cuadrado {
  width: 18px;
  height: 18px;
  max-width: 100%;
  border-radius: 3px;
  text-align: center;
  border: 1px solid var(--green-color-shadow);
}
.cuadrado.hovered {
  background-color: #b3e0ff;
}
.max-w {
  max-width: 20px;
}
::v-deep .v-label {
  margin-bottom: 0px;
}
.my-label {
  cursor: pointer;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0px;
}

.menu-card-listas {
  width: 120px;
  border-radius: 1px;
  border: 1px solid #0d0d0dce;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #EDEDED !important;
}
.menu-card-alinear {
  width: 160px;
  border-radius: 1px;
  border: 1px solid #0d0d0dce;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #EDEDED !important;
}

.menu-card-listas, .menu-card-alinear .v-btn {
  font-size: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.menu-row {
  gap: 12px;
  max-height: 40px;
  overflow: hidden;
}

.preview-container {
  border-radius: 3px;
  border: 1px solid #929191a6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.fullScreen {
  border-radius: 5px !important;
  bottom: 0 !important;
  height: 100% !important;
  left: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 500;
  border: 2px solid #adacaccd;
  background: #FAFAFA;
  min-height: 100% !important;
}
// .ProseMirror {
//   padding: 8px 8px !important;
// }
.ProseMirror:not(.small-h > .ProseMirror) {
  min-height: 60vh;
  padding: 8px 8px !important;
}
.tiptap .ProseMirror .ProseMirror-focused {
  min-height: 60vh;
  padding: 8px 8px !important;
  background-color: red;
}

.h-editor-wraper-fullScreen > .ProseMirror {
  min-height: 93vh !important;
}


.small-h {
  height: 30vh;
}

//encabezado y pie
.small-h > .ProseMirror {
  height: 30vh;
}

</style>

<style scoped>
div {
  transition: all 0.3s cubic-bezier(0.42, 0.0, 0.58, 1.0);
}
::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 4px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  padding: 0;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 0px 3px 0;
}
</style>
